import React from 'react'

import { Tag } from '../../../../../../types/project'
import styles from './Hero.module.scss'

interface IHero {
    title: string,
    tags: Tag[],
    description: string,
    heroImage: string,
}

const Hero = ({ title, tags, description}: IHero) => (
    <div className={styles.heroRootContainer}>
        <h1>
            {title}
        </h1>
        <div className={styles.tagContainer}>
            <ul>
                {tags.map(tag => (
                    <li key={tag.id}>
                        {tag.name}
                    </li>
                ))}
            </ul>
        </div>
        <div className={styles.descriptionContainer}>
            <p>
                {description}
            </p>
        </div>
    </div>
)

export default Hero