import React, { useState, useEffect } from 'react'
import { getYoutubeVideos } from '../../../../api/youtTubeClient'

import styles from './Youtube.module.scss'

interface YoutubeVideo {
    snippet: any,
    etag: string,
    id: any,
}

const Youtube = () => {
    const [videos, setVideos] = useState<Array<YoutubeVideo>>([])

    useEffect(() => {
        async function fetchYoutubeVideos() {
          const videos = await getYoutubeVideos()
          setVideos(videos.items)
        }
        fetchYoutubeVideos()
      }, [])

    return (
        <div className={styles.youtubeRootContainer}>
            {
                videos && videos.length > 0 && (
                    <ul className={styles.videoList}>
                        {
                            videos.map(video => (
                                <li>
                                    <img alt={video.snippet.title} src={`https://i.ytimg.com/vi/${video.id.videoId}/maxresdefault.jpg`} />
                                    <div className={styles.metaContainer}>
                                        <p className={styles.title}>
                                            {video.snippet.title}
                                        </p>
                                        <p className={styles.meta}>
                                            <span>
                                                {video.snippet.publishedAt}
                                            </span>
                                        </p>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                )
            }
        </div>
    )
}

export default Youtube