import React from 'react'

import Home from '../pages/Home'
import Feeds from '../pages/Feeds'
import Projects from '../pages/Projects'
import BottomNavigator from '../components/BottomNavigator'
import Info from '../pages/Info'
import Header from '../components/Header'
import { AnimatedRoutes, RouteTransition } from '../components/Common/TransitionRoute'
import FeedBottomNavigator from '../pages/Feeds/components/FeedBottomNavigator'
import Instagram from '../pages/Feeds/pages/Instagram'

const AppRoot = () => {
    return (
        (
            <>
                <Header />
                <AnimatedRoutes exitBeforeEnter>
                    <RouteTransition path="/" exact>
                        <Home />
                    </RouteTransition>
                    <RouteTransition exact path="/feeds">
                        <Instagram />
                    </RouteTransition>
                    <RouteTransition path="/feeds">
                        <Feeds />
                    </RouteTransition>
                    <RouteTransition path="/projects/:projectSlug?" exact>
                        <Projects />
                    </RouteTransition>
                    <RouteTransition path="/info" exact>
                        <Info />
                    </RouteTransition>
                </AnimatedRoutes>
                <BottomNavigator variant="SUBPAGE" />
                <FeedBottomNavigator />
            </>
        )
    )
}

export default AppRoot