import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Project } from '../../types/project'
import Meta from './components/Meta'
import { AnimationProps, motion } from 'framer-motion'

import styles from './Project.module.scss'

interface IOneProject {
    backgroundImage: string,
}

const OneProject = styled(motion.div)<IOneProject>`
    background-image: url(${props => props.backgroundImage});
`

interface IProject {
    project: Project
    animation: AnimationProps["variants"]
}

const ProjectComponent = ({ project, animation }: IProject) => {
    const history = useHistory()

    function handleNavigation(slug: string) {
        history.push(`/projects/${slug}`)
    }
    return (
        <OneProject
            onClick={() => handleNavigation(project.projectSlug)} backgroundImage={project.heroImage.url} className={styles.oneProject}
            variants={animation}
        >
            <Meta title={project.title} meta={project.meta.tags} />
        </OneProject>
    )
}

export default ProjectComponent