import logoFeeds from '../../assets/logos/logo-feeds.svg'
import logoInfo from '../../assets/logos/logo-info.svg'
import logoProjects from '../../assets/logos/logo-info.svg'

export interface MenuItem {
    label: string,
    target: string,
    menuColor: string,
    logo?: string | undefined,
}

export const menuItems = {
    items: [
        { id: 1, label: 'Project', target: '/projects', menuColor: '#3F20A4', logo: logoProjects },
        { id: 2, label: 'Feeds', target: '/feeds', menuColor: '#F70237', logo: logoFeeds },
        { id: 3, label: 'Shop', target: '/shop', menuColor: '#2C3A6A', logo: undefined, },
        { id: 4, label: 'Info', target: '/info', menuColor: '#A4AF70', logo: logoInfo },
    ],
    getAllMenuItems: (): MenuItem[] => menuItems.items ?? []
}