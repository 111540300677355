import React from 'react'

import BottomNavigator from '../../components/BottomNavigator'
import PlaceHolder from './components/PlaceHolder'
import styles from './Home.module.scss'

const Home = () => (
    <div className={styles.homePageRootContainer}>
        <div className={styles.innerContainer}>
            <PlaceHolder />
            <div className={styles.introTextContainer}>
                <p className={styles.introText}>
                    I'm a creative professional from Helsinki, Finland. I design apps, websites and brands. I also write, make videos, take photos and create concept art.
                </p>
            </div>
        </div>
    </div>
)

export default Home