import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getAllMenuItems } from '../../api/fakeApi'

import styles from './Header.module.scss'

const Header = () => {
    const location = useLocation()
    const history = useHistory()

    function getImage(): string {
        const logoImage = getAllMenuItems.find(item => location.pathname.includes(item.target))?.logo
        if (logoImage) {
            return logoImage
        }
        return ''
    }

    return (
        <div onClick={() => history.push('/')} className={styles.headerRootContainer}>
            {
                getImage() && <img alt="Mikko Oittinen" src={getImage()} />
            }
        </div>
    )
}

export default Header