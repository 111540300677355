import React from 'react'
import { format } from 'date-fns'
import readingTime from 'reading-time'
import striptags from 'striptags'

import { SingleMediumPost } from '../../../../../../types/medium'
import styles from './Post.module.scss'

interface IPost {
    post: SingleMediumPost
}


const Post = ({ post }: IPost) => (
    <div className={styles.onePostRootContainer}>
        <h1 className={styles.postTitle}>
            {post.title}
        </h1>
        <div className={styles.metaContainer}>
            <p>
                {
                format(new Date(post.created), 'MMM d Y')
                }
            </p>
            <p>
            {
                Math.round(readingTime(striptags(post.content)).minutes)
            } mins read
            </p>
        </div>
        <div dangerouslySetInnerHTML={{__html: post.content}} className={styles.postContentContainer} />
    </div>
)

export default Post