import React from 'react'
import styled, { ThemedStyledProps } from 'styled-components'
import classnames from 'classnames'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'


import { MenuItem } from '../../../../api/fakeApi/menuItems'
import styles from './MenuItem.module.scss'

interface IMenuitem extends MenuItem {
    isActive: boolean
    variant: string
}

interface IstyledMenu extends ThemedStyledProps<any, any> {
    isActive: boolean
}

const StyledMenu = styled.span<IstyledMenu>`
    color: ${props => props.isActive ? '#fff' : props.color};
    position: relative;
    z-index: 9;
`

const SingleMenuItem = ({ target, label, menuColor, isActive, variant }: IMenuitem) => (
    <Link
        to={target}
        className={
            classnames(
            styles.singleMenuItem,
            styles[String(variant).toLowerCase()]
        )}
        href={target}>
        <StyledMenu isActive={isActive} color={menuColor}>
            {label}
        </StyledMenu>
        { isActive && variant !== 'feed' && (
            <motion.span
                layoutId="active"
                className={styles.activeIndicator}
                initial={false}
                animate={{ backgroundColor: menuColor }}
                transition={{
                    type: "spring",
                    stiffness: 500,
                    damping: 30
                }}
            />
        ) }
        { isActive && variant === 'feed' && (
            <motion.span
                initial={false}
                className={styles.activeCarat}
                layoutId="active"
                transition={{
                    type: "spring",
                    stiffness: 500,
                    damping: 30
                }}
            />
        )}
    </Link>
)
            

export default SingleMenuItem