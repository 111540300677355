import React, { useEffect, useState } from 'react'
import getMediumPosts from '../../../../api/MediumClient'

import { SingleMediumPost } from '../../../../types/medium'
import MediumSidebar from './components/MediumSidebar'
import Post from './components/Post'

import styles from './Medium.module.scss'

const Medium = () => {
    const [posts, setPosts] = useState<Array<SingleMediumPost>>([])
    // @ts-ignore
    useEffect(() => {
        async function fetchMediumPosts() {
          const mediumPosts = await getMediumPosts()
          setPosts(mediumPosts)
        }
    
        fetchMediumPosts()
      }, [])

    return (
        <div className={styles.mediumRoot}>
            <div className={styles.mediumInnerContainer}>
                <MediumSidebar posts={posts}/>
                    <div className={styles.postsRootContainer}>
                    {posts && posts.length > 0 && (
                        posts.map(post => (
                            <Post post={post} />
                        ))
                    )}
                </div>
            </div>
        </div>
    )
}

export default Medium