import { gql, useQuery } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'

import Mikko from '../../assets/images/mikko_1.png'
import { Social } from '../../types/social'
import styles from './Info.module.scss'

const fetchSocialInfo = gql`
query getSocials {
    socials {
      id,
      target,
      name,
      icon {
        url
      }
    }
  }
`

interface IRootContainerProps {
    profilePicture: string;
}

const InfoRootContainer = styled.div<IRootContainerProps>`
@media (max-width: 576px) {
    &:before {
        background-image: url(${props => props.profilePicture});
    }
}
`

const ImageContainer = styled.div<IRootContainerProps>`
    background-image: url(${props => props.profilePicture});
`

const Info = () => {
    const { loading, error, data: socials } = useQuery(fetchSocialInfo);
    return (
        <InfoRootContainer profilePicture={Mikko} className={styles.infoRootContainer}>
            <ImageContainer profilePicture={Mikko} className={styles.imageContainer} />
            <div className={styles.right}>
                <div className={styles.rightInner}>
                    <p>
                        I'm not exactly looking for a job right now, but if you have a cool project idea or something, feel free to contact me.
                    </p>
                    <h1>
                        mikko@oittinen.fi
                    </h1>
                    <ul>
                        {
                            (socials && Array.isArray(socials.socials) && socials.socials.length > 0) && (
                                <ul>
                                    {
                                        socials.socials.map(((social: Social) => (
                                            <li key={social.id}>
                                                <a rel="noreferrer noopener" href={social.target} target="_blank">
                                                    <span>
                                                        <img src={social.icon.url} alt={social.name} />
                                                    </span>
                                                    {social.name}
                                                </a>
                                            </li>
                                        )))
                                    }
                                </ul>
                            )
                        }
                    </ul>
                </div>
            </div>
        </InfoRootContainer>
    )
}

export default Info