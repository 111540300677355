import React, { useEffect, useState } from 'react'
import Masonry from 'react-masonry-css'
import { motion } from 'framer-motion'

import { getInstagramPosts } from '../../../../api/InstagramClient'
import styles from './Instagram.module.scss'

const instaPostContainerAnimation = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const instaPostAnimation = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

const Instagram = () => {
    const [instaPosts, setInstaPosts] = useState<Array<any>>([])

    async function fetchPosts() {
        const posts: any = await getInstagramPosts()
        setInstaPosts(posts.data)
    }

    useEffect(() => {
        fetchPosts()

    }, [])

    return (
    <div className={styles.instagramRootContainer}>
        {
            (Array.isArray(instaPosts) && instaPosts.length > 0) && (
                <motion.div
                    className={styles.masonry}
                    initial="hidden"
                    animate="show"
                    variants={instaPostContainerAnimation}
                >
                    <Masonry
                        breakpointCols={6}
                        className={styles.grid}
                        columnClassName={styles.gridCol}>
                        {
                            instaPosts.map(post => (
                                <motion.div
                                    variants={instaPostAnimation}
                                >
                                    <img key={post.id} src={post.media_url} alt={post.permalink} />
                                    <p className={styles.caption}>
                                        {post.caption}
                                    </p>
                                </motion.div>
                            ))
                        }
                        </Masonry>
                    </motion.div>
            )
        }
        {
            (Array.isArray(instaPosts) && instaPosts.length > 0) && (
                <motion.div
                    className={styles.masonryMobile}
                    initial="hidden"
                    animate="show"
                    variants={instaPostContainerAnimation}
                >
                        {
                            instaPosts.map(post => (
                                <motion.div
                                    variants={instaPostAnimation}
                                >
                                    <img key={post.id} src={post.media_url} alt={post.permalink} />
                                    <p className={styles.caption}>
                                        {post.caption}
                                    </p>
                                </motion.div>
                            ))
                        }
                </motion.div>
            )
        }
    </div>
    )
}

export default Instagram