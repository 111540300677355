import axios from 'axios';

const apiToken = 'AIzaSyD1wiFpxbKBZUaT-2xoMwYMzUm8YULqsgg'

const youtubeApiInstance = axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3'
})

export const getYoutubeVideos = async () => {
    const apiResponse = await youtubeApiInstance.get('/search', {
        params: {
            key: apiToken,
            channelId: 'UChUl9DYGrz2rQ4avj12Dm3Q',
            part: 'snippet,id',
            order: 'date',
            maxResults: 50
        }
    })
    return apiResponse.data
}