import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AppRoot from './Root'

function App() {
  return (
    <Router>
        <AppRoot />
    </Router>
  );
}

export default App;
