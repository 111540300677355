import axios from 'axios'

const instagramApiInstance = axios.create({
    baseURL: 'https://graph.instagram.com/me'
})



export const getInstagramPosts = async () => {
    const apiResponse = await instagramApiInstance.get(`media?fields=media_count,media_type,caption,permalink,media_url&access_token=${process.env.REACT_APP_INSTAGRAM_TOKEN}`)
    return apiResponse.data
}