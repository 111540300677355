import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom';
import OneProject from '../../components/Project';
import { Project } from '../../types/project'
import ProjectPage from '../Project';
import { motion } from 'framer-motion'

import styles from './Projects.module.scss'

const fetchProjectsQuery = gql`
    query getTags {
        projects {
            id,
            title,
            meta
            description,
            projectSlug,
            heroImage {
                url
            }
            singleProjects {
              isRight,
              text,
              image {
                url
              }
            }
          }
    }
`

const projectContainerAnimation = {
    hidden: {
        opacity: 0,
        y: -10,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

const Projects = () => {
    const { loading, error, data: projects } = useQuery(fetchProjectsQuery);
    const { projectSlug }: any = useParams()

    return (
        !projectSlug ? (
            <div className={styles.outerContainer}>
                {
                    projects && Array.isArray(projects.projects) && projects.projects.length > 0 && (
                        <motion.div
                            className={styles.projectsRootContainer}
                            initial="hidden"
                            animate="show"
                            variants={projectContainerAnimation}
                        >
                            {
                                projects.projects.map((project: Project) => (
                                    <OneProject
                                        project={project}
                                        animation={projectContainerAnimation}
                                    />
                                ))
                            }
                        </motion.div>
                    )
                }
            </div>
        ) : <ProjectPage />
    )
}

export default Projects