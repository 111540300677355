import React from 'react'
import { Link } from 'react-router-dom';
import { format } from 'date-fns'
import readingTime from 'reading-time'
import striptags from 'striptags'

import { SingleMediumPost } from '../../../../../../types/medium';
import styles from './MediumSidebar.module.scss'

interface IMediumSidebar {
    posts: SingleMediumPost[]
}

const MediumSidebar = ({
    posts
}: IMediumSidebar) => (
    <div className={styles.feedSidebarRootContainer}>
        <div className={styles.header}>
            <p className={styles.title}>
                Mikko oittinen <span>on Medium</span>
            </p>
        </div>
        <div className={styles.postNavigationContainer}>
            {posts.length > 0 && (
                <ul className={styles.postNavList}>
                    {posts.map(post => (
                        <li>
                            <Link to="#">
                                {post.title}
                            </Link>
                            <div className={styles.meta}>
                                <p>
                                {   format(new Date(post.created), 'MMM d Y')}
                                </p>
                                <p>
                                {
                                    Math.round(readingTime(striptags(post.content)).minutes)
                                } mins read
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    </div>
)

export default MediumSidebar;