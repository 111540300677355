import React from 'react'
import { Tag } from '../../../types/project'

import styles from './Meta.module.scss'

interface IMeta {
    title: string,
    meta: Tag[]
}

const Meta = ({
    title,
    meta
}: IMeta) => (
    <div className={styles.metaContainer}>
        <p className={styles.projectTitle}>
            {title}
        </p>
        <ul className={styles.tags}>
            {Array.isArray(meta) && meta.length > 0 && (
                meta.map((tag: Tag) => (
                    <li>
                        {tag}
                    </li>
                ))
            )}
        </ul>
    </div>
)

export default Meta