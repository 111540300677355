import React from 'react'
import shortid from 'shortid'
import { parse } from 'rss-to-json'
import { MediumPost, SingleMediumPost } from '../../types/medium'


async function getMediumPosts() {
    const parsedData: MediumPost = await parse('https://thingproxy.freeboard.io/fetch/https://medium.com/feed/@mikkooittinen', {})
    const result = {
        ...parsedData,
        items: parsedData.items.map((post: SingleMediumPost) => ({
            ...post,
            id: shortid.generate()
        }))
    }
    return Promise.resolve(result.items)
    
}

export default getMediumPosts