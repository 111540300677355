import React from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { sortBy } from 'lodash'

import Hero from '../Projects/components/Project/components/Hero'
import styles from './Project.module.scss'
import { Project as ProjectType, SingleProject } from '../../types/project'
import shortid from 'shortid'
import ProjectBlock from '../Projects/components/Project/components/ProjectBlock'

const fetchSingleProject = gql`
query getProjectBySlug($projectSlug: String!) {
    project(where: {projectSlug: $projectSlug}) {
      id
      title
      description
      createdAt
      meta
      heroImage {
        url
      }
      singleProjects {
        id
        title
        image {
          url
        }
        isRight
        order
        text
      }
    }
  }
  
`
interface ProjectQuery {
  projectSlug: string,
}

interface ProjectResult {
  project: ProjectType,
}

const orderProjectBlock = (blocks: SingleProject[]) => sortBy(blocks, (block) => block.order)

const Project = () => {
    const { projectSlug }: any = useParams()
    const { loading, error, data} = useQuery<ProjectResult, ProjectQuery>(fetchSingleProject, {
        variables: {
            projectSlug,
        },
    })

    return (
        <div className={styles.singleProjectRootContainer}>
            {
              data && <Hero tags={data.project.meta.tags.map((tag) => ({name: String(tag), id: shortid.generate()}))} description={data.project.description} title={data.project.title} heroImage={data.project.heroImage.url} />
            }
            {
              data && <div className={styles.block}>
                {
                  orderProjectBlock(data.project.singleProjects).map((block) => (
                    <ProjectBlock data={block} />
                  ))
                }
              </div>
            }
        </div>
    )
}

export default Project