import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import { SingleProject } from '../../../../../../types/project'
import styles from './ProjectBlock.module.scss'

interface IProjectBlock {
    data: SingleProject
}

interface IProjectBlockImage {
    blockImage: string,
}

const ProjectBlockImage = styled.div<IProjectBlockImage>`
    background: url(${props => props.blockImage});
`

const ProjectBlock = ({ data }: IProjectBlock) => (
    <div className={styles.projectBlockRootContainer}>
        <ProjectBlockImage
            className={
                classnames(
                    data.isRight && styles.toRight,
                    styles.imageContainer
                )
            }
            blockImage={data.image.url}
        />
        <div className={classnames(
            styles.metaContainer,
            data.isRight && styles.toLeft
        )}>
            <h1>
                {data.title}
            </h1>
            <p>
                {data.text}
            </p>
        </div>
    </div>
)

export default ProjectBlock
