import React, { useEffect } from 'react';
import { useRouteMatch, Switch, Route, useHistory, useParams } from 'react-router-dom';
import { AnimatedRoutes, RouteTransition } from '../../components/Common/TransitionRoute';
import FeedBottomNavigator from './components/FeedBottomNavigator';
import Instagram from './pages/Instagram';
import Medium from './pages/Medium';
import Youtube from './pages/Youtube'

function RenderFeed():React.ReactElement {
    // @ts-ignore
    const { feedId } = useParams()
    console.debug('feedId', feedId)
    if (feedId === 'medium') {
        return <Medium />
    } else if (feedId === 'instagram') {
        return <Instagram />
    } else if (feedId === 'youtube') {
        return <Youtube />
    }
    return (
        <p></p>
    )
}

const Feed = () => {
    const { path } = useRouteMatch();
    return (
        <div>
            <AnimatedRoutes exitBeforeEnter>
                <RouteTransition path={`${path}/:feedId`}>
                    <RenderFeed />
                </RouteTransition>
            </AnimatedRoutes>
        </div>
    )
}

export default Feed