import React from 'react';
import styled from 'styled-components'

import placeHolderImage from '../../../../assets/placeholder.png'

import styles from './Placeholder.module.scss'

interface ImageProps {
    image: string
}

const Image = styled.div<ImageProps>`
    background-image: urL(${props => props.image});
`

const PlaceHolder = () => (
    <Image image={placeHolderImage} className={styles.placeHolderRootContainer} />
)

export default PlaceHolder