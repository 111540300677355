import React from 'react';
import { getAllMenuItems } from '../../api/fakeApi';
import classnames from 'classnames';
import { AnimateSharedLayout } from "framer-motion"

import { MenuItem } from '../../api/fakeApi/menuItems'
import styles from './BottomNavigator.module.scss'
import SingleMenuItem from './components/MenuItem';
import { useLocation } from 'react-router-dom';

type BottomNavType = "HOME" | "SUBPAGE"

interface IBottomNavType {
    variant: BottomNavType;
}

function filterMenuItems(menuItems: MenuItem[], currentPathName: string):MenuItem[] {
    let result: MenuItem[] = menuItems
    if (currentPathName !== '/') {
        result = menuItems.filter((item: MenuItem) => item.label.toLowerCase() !== 'shop')
    }
    return result
}

const BottomNavigator = ({
    variant
}: IBottomNavType) => {
    const location = useLocation()
    
    const isActive = (target: string) => {
        const result = location.pathname.includes(target)
        return result
    }
    return (
        <div className={classnames(styles.bottomNavigatorContainer, styles[variant.toLowerCase()])}>
            <AnimateSharedLayout>
                {
                    filterMenuItems(getAllMenuItems, location.pathname).map((menuItem: MenuItem) => (
                        <SingleMenuItem
                            label={menuItem.label}
                            target={menuItem.target}
                            menuColor={menuItem.menuColor}
                            isActive={isActive(menuItem.target)}
                            variant={variant}
                        />
                    ))
                }
            </AnimateSharedLayout>
        </div>
    )
}

export default BottomNavigator