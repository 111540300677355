import React from 'react';
import { AnimateSharedLayout } from 'framer-motion'

import SingleMenuItem from '../../../../components/BottomNavigator/components/MenuItem';
import styles from './FeedBottomNavigator.module.scss'
import { useLocation } from 'react-router-dom';

const FEED_MENU = [
    { target: "/feeds/instagram", label: "Instagram" },
    { target: "/feeds/youtube", label: "Youtube" },
    { target: "/feeds/medium", label: "Medium" }
]

const FeedBottomNavigator = () => {
    const location = useLocation()
    return (
        <>
            {
                location.pathname.includes('feed') && (
                    <div className={styles.feedBottomNavigatorRoot}>
                        <AnimateSharedLayout>
                            {
                                FEED_MENU.map(menuItem => (
                                    <SingleMenuItem
                                        target={menuItem.target}
                                        isActive={menuItem.target === location.pathname}
                                        menuColor="#fff"
                                        variant="feed"
                                        label={menuItem.label}
                                    />
                                ))
                            }
                        </AnimateSharedLayout>
                    </div>
                )
            }
        </>
    )
}

export default FeedBottomNavigator